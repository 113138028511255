import * as Yup from 'yup';

export class CreateExpenseTypesSchema {
  schema;

  initialValues;

  constructor() {
    this.schema = Yup.object({
      name: Yup.string().required('Name required'),
      description: Yup.string().optional(),
      balance: Yup.number().optional()
    });

    this.initialValues = {
      name: '',
      description: '',
      balance: 0
    };

    this.updateValues = {
      ...this.initialValues,
      _id: ''
    };
  }
}
