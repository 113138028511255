// material
import { Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';

export default function TwoFASuccess({ title = '2FA Enabled Successfully!' }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 3 }}>
      <Icon icon="icon-park-solid:success" width={84} height={84} color="#08ae5a" />

      <Typography variant="h5" color="primary">
        {title}
      </Typography>
      <Typography variant="subtitle1" color="GrayText">
        Your account is secured. You can disable 2FA anytime you want!.
      </Typography>
    </Box>
  );
}
