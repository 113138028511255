import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, Card, Grid, FormGroup, FormControlLabel, Switch, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAuth } from 'src/hooks';
import { AuthApi, deleteApi, getApi, postApi } from 'src/common/apis';
import { toast } from 'react-hot-toast';

import TwoFAQRCode from './TwoFAQRCode';
import TwoFAQRCodeInput from './TwoFACodeInput';
import TwoFASuccess from './TwoFASuccess';

const CTabs = {
  SHOW_QR: 'show_qr',
  CODE_INPUT: 'code_input',
  SUCCESS: 'success',
  TOGGLE_2FA: 'toggle_2fa',
  RESET_2FA: 'reset_2fa',
  NONE: 'none'
};

export default function TwoFA() {
  const { user, updateInfo } = useAuth();
  const [tab, setTab] = useState(CTabs.NONE);
  const [twoFA, setTwoFA] = useState(user?.isTwoFactorAuthenticationEnabled || false);
  const [isSubmitting, setIsSubmitting] = useState(CTabs.NONE);
  const [twoFAImage, setTwoFAImage] = useState(null);

  const handleSavechanges = async () => {
    setIsSubmitting(CTabs.TOGGLE_2FA);
    if (twoFA) {
      const res = await getApi({ url: AuthApi.generate2FA, showToast: true });
      setIsSubmitting(CTabs.NONE);

      if (!res || res.error) return;

      setTwoFAImage(res.data.qrCode);
      setTab(CTabs.SHOW_QR);
    } else {
      const res = await deleteApi({ url: AuthApi.disable2FA, showToast: true });
      setIsSubmitting(CTabs.NONE);
      if (!res || res.error) return;
      updateInfo({ ...res.data });
    }
  };

  const handleVerify = async (code) => {
    if (code.length < 4) return toast.error('Please enter a valid auth code!');

    setIsSubmitting(CTabs.CODE_INPUT);

    const res = await postApi({ url: AuthApi.verify2FA, values: { code }, showToast: true });

    setIsSubmitting(CTabs.NONE);

    if (!res || res?.error) return;

    // update user here
    updateInfo({ ...res.data });
    setTab(CTabs.NONE);
  };

  const getTabs = () => {
    switch (tab) {
      case CTabs.NONE:
        return '';

      case CTabs.SHOW_QR:
        return <TwoFAQRCode twoFAImage={twoFAImage} onContinue={() => setTab(CTabs.CODE_INPUT)} />;

      case CTabs.CODE_INPUT:
        return (
          <TwoFAQRCodeInput
            onVerify={handleVerify}
            loading={isSubmitting === CTabs.CODE_INPUT}
            goBack={() => setTab(CTabs.SHOW_QR)}
          />
        );

      case CTabs.SUCCESS:
        return <TwoFASuccess />;

      default:
        return '';
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Card sx={{ p: 3 }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={twoFA} onChange={(e) => setTwoFA(e.target.checked)} />}
              label={twoFA ? '2FA is Active' : '2FA is disabled (Enable it for better security of your account)'}
            />
          </FormGroup>
          <Typography variant="body2" color="GrayText">
            Two Factor Authentication, or 2FA, is an extra layer of protection used to ensure the security of online
            accounts beyond just a username and password.
          </Typography>

          {getTabs()}

          {tab === CTabs.NONE && (
            <>
              {user?.isTwoFactorAuthenticationEnabled && <TwoFASuccess title="2FA is Active" />}

              <Stack spacing={3} alignItems="flex-end" mt={3}>
                <LoadingButton
                  variant="contained"
                  loading={isSubmitting === CTabs.TOGGLE_2FA}
                  onClick={handleSavechanges}
                  color={user?.isTwoFactorAuthenticationEnabled ? 'error' : 'primary'}
                  disabled={user?.isTwoFactorAuthenticationEnabled === twoFA}
                >
                  {user?.isTwoFactorAuthenticationEnabled ? 'Disable 2FA' : 'Enable 2FA'}
                </LoadingButton>
              </Stack>
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
