import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

// apis
import { AuthApi, UsersApi, patchApi, postApi } from 'src/common/apis';

// constants
import { EmptyTelegramIntegration } from 'src/common/constants';

// utils
import axiosInstance from 'src/utils/axiosInstance';
import { generateAuthUserData, getSession, isValidToken, setLocalUser, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  UPDATE_USER: (state, action) => {
    const { user } = action.payload;

    const newUser = {
      ...state.user,
      ...user
    };
    localStorage.setItem('user', JSON.stringify(newUser));

    return {
      ...state,
      user: newUser
    };
  },
  LOGOUT_TELEGRAM: (state) => {
    const newUser = {
      ...state.user,
      platformIntegrations: {
        telegram: EmptyTelegramIntegration
      }
    };
    localStorage.setItem('user', JSON.stringify(newUser));
    return {
      ...state,
      user: newUser
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  twoFAAuthenticate: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  logoutTelegram: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  updateInfo: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const { accessToken, user } = getSession();

        if (accessToken && isValidToken(accessToken)) {
          const response = await axiosInstance.get(UsersApi.me);
          const user = generateAuthUserData(response?.data?.data?.user);

          if (!user) {
            setSession(null);
            dispatch({ type: 'LOGOUT' });
          }

          localStorage.setItem('user', JSON.stringify(user));

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await postApi({ url: AuthApi.login, values: { username: email, password }, showToast: true });

    if (response.error) return;

    const { accessToken, user, twoFAKey } = response.data;

    if (twoFAKey) return twoFAKey;

    const authUser = generateAuthUserData(user);

    setSession(accessToken, authUser);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: authUser
      }
    });
  };

  const twoFAAuthenticate = async (email, code, twoFAKey) => {
    const response = await postApi({
      url: AuthApi.authenticate2FA,
      values: { username: email, code, twoFAKey },
      showToast: true
    });

    if (response.error) return;

    const { accessToken, user } = response.data;

    const authUser = generateAuthUserData(user);

    setSession(accessToken, authUser);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: authUser
      }
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axiosInstance.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = () => {};

  const changePassword = async (values) => {
    const response = await postApi({ url: AuthApi.changePassword, values, showToast: true });

    if (response.error) return;

    const { accessToken, user } = response.data;

    const authUser = generateAuthUserData(user);

    setSession(accessToken, authUser);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: authUser
      }
    });
  };

  const updateProfile = async (values) => {
    const response = await patchApi({ url: UsersApi.base, values: values, showToast: true });

    if (response.error) return;

    const { user } = response.data;

    const authUser = generateAuthUserData(user);

    setLocalUser(authUser);
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user: user
      }
    });
  };

  const logoutTelegram = async () => {
    dispatch({
      type: 'LOGOUT_TELEGRAM'
    });
  };

  const updateInfo = (user) => {
    const authUser = generateAuthUserData(user);

    setLocalUser(authUser);
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user: user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        logoutTelegram,
        changePassword,
        updateInfo,
        twoFAAuthenticate
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
