import { useSelector } from 'react-redux';

export default function useObjectTypes() {
  const { isLoading, error, isDataLoaded, accountsNo, banks, merchants } = useSelector((state) => state.objectTypes);

  const objectTypesSettings = {
    isLoading,
    error,
    isDataLoaded
  };

  return { objectTypesSettings, accountsNo, banks, merchants };
}
