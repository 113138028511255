import * as Yup from 'yup';

export class CreateTransactionSchema {
  schema;

  initialValues;

  constructor() {
    const parsedCommand = Yup.object({
      amount: Yup.number().required('Amount is required'),
      transactionType: Yup.string().required('Transaction Type is required'),
      method: Yup.string().required('Payment Method is required'),
      receivedFrom: Yup.string().optional(),
      transactionId: Yup.string().required('Transaction id required!')
    });

    this.schema = Yup.object({
      accountNo: Yup.mixed()
        .required('Please enter or select account no')
        .test(
          'valid-value',
          'Please enter or select a valid account no',
          (value) => typeof value === 'string' || typeof value === 'object'
        ),
      // receipt: Yup.mixed()
      //   .required('Please enter or select receipt')
      //   .test(
      //     'valid-value',
      //     'Please enter or select a valid receipt',
      //     (value) => typeof value === 'string' || typeof value === 'object'
      //   ),
      bank: Yup.mixed()
        .required('Please enter or select bank')
        .test(
          'valid-value',
          'Please enter or select a valid bank',
          (value) => typeof value === 'string' || typeof value === 'object'
        ),
      merchant: Yup.mixed()
        .optional()
        .test(
          'valid-value',
          'Please enter or select account no',
          (value) => typeof value === 'string' || typeof value === 'object'
        ),
      parsedCommand: parsedCommand,
      expenseType: Yup.string().required('Please select an expense'),
      category: Yup.string().required('Please select transaction category!'),
      transactionMode: Yup.string().required('Transaction Mode is required'),
      transactionDate: Yup.string().required('Please enter transaction date!'),
      remark: Yup.string().optional(),
      originalCommand: Yup.string().optional() //only for update
    });

    this.initialValues = {
      parsedCommand: {
        transactionId: '',
        amount: 0,
        receivedFrom: '',
        transactionType: '',
        method: ''
      },
      transactionDate: '',
      transactionMode: '',
      expenseType: '',
      category: '',
      remark: '',
      originalCommand: '',
      accountNo: { value: '', lebal: '' },
      bank: { value: '', lebal: '' },
      merchant: { value: '', lebal: '' }
    };

    this.updateValues = {
      ...this.initialValues,
      _id: ''
    };
  }

  // async createTableData(transactions) {
  //   return transactions.map((item) => {
  //     return {
  //       Type: item?.parsedCommand?.transactionType,
  //       bank: item?.parsedCommand?.bank || 'N/A',
  //       Mode: item.transactionMode,
  //       amount: `${item.parsedCommand.amount} .rs`,
  //       _id: item._id,
  //       category: item?.category?.name || 'N/A',
  //       channel: item?.channel?.title || 'UnKnown',
  //       date: moment(item.createdAt).format('YYYY-MM-DD') || 'N/A',
  //       expense: item?.expenseType?.name || 'N/A',
  //       remark: item.remark
  //       // merchant: item?.parsedCommand?.merchant || 'N/A',
  //     };
  //   });
  // }

  // parsedCommand: {
  //   transactionId: 'asjkdnasjkna12',
  //   accountNo: '2001',
  //   amount: 1100,
  //   availableBalance: 10,
  //   bank: 'ICICI',
  //   merchant: 'Amezon',
  //   receivedFrom: 'Shashank',
  //   transactionType: 'debit',
  //   method: 'card'
  // },
  // transactionMode: 'manual',
  // expenseType: '',
  // category: '',
  // remark: 'Testing 1'
}
