import { Form, FormikProvider, useFormik } from 'formik';

// material
import { Box, Grid, Card, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// hooks
import useAuth from 'src/hooks/useAuth';

import { UserAccountSchema } from 'src/common/schemas';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function AccountGeneral() {
  const userAccountSchema = new UserAccountSchema();
  const { user, updateProfile } = useAuth();

  useEffect(() => {
    console.log('log: user', user);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...userAccountSchema.initialValues,
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.email,
      phoneNumber: user.phoneNumber,
      about: user.about
    },

    validationSchema: userAccountSchema.schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateProfile(values);

        setSubmitting(false);
      } catch (error) {
        setErrors({ afterSubmit: error.code });
        setSubmitting(false);
      }
    }
  });

  const { isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField fullWidth label="First Name" {...getFieldProps('firstName')} />
                  <TextField fullWidth label="Last Name" {...getFieldProps('lastName')} />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField fullWidth label="Username" {...getFieldProps('username')} />
                  <TextField fullWidth label="Phone Number" {...getFieldProps('phoneNumber')} />
                </Stack>

                <TextField {...getFieldProps('about')} fullWidth multiline minRows={4} maxRows={4} label="About" />
              </Stack>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
