const CTransactionType = {
  CREDIT: 'credit',
  DEBIT: 'debit',
  WITHDRAWAL: 'withdrawal',
  DEPOSIT: 'deposit',
  UNKNOWN: 'unknown'
};

const CPaymentMethod = {
  CARD: 'card',
  CASH: 'cash',
  WITHDRAWAL: 'withdrawal',
  BANK: 'Bank',
  UPI: 'upi',
  NET_BANKING: 'net banking',
  IMPS: 'imps',
  RTGS: 'rtgs',
  NEFT: 'neft',
  UNKNOWN: 'unknown'
};

const CTransactionMode = {
  MANUAL: 'manual',
  TELEGRAM: 'telegram'
};

const CTransactionChartFilterOptions = {
  groupByYearOptions: {
    WEEK: 'weekly',
    MONTH: 'monthly',
    YEAR: 'yearly'
  }
};

export { CPaymentMethod, CTransactionType, CTransactionMode, CTransactionChartFilterOptions };
