// material
import { Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function TwoFAQRCode({ twoFAImage, onContinue = () => {} }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 3 }}>
      <Typography variant="h5">Scan QR Code</Typography>
      <Typography variant="body2">Scan this qr code to verify a device</Typography>
      <img src={twoFAImage} alt="2FA QR Code" width="20%" />

      <LoadingButton variant="contained" onClick={onContinue} fullWidth sx={{ maxWidth: 200, mt: 3 }}>
        Continue
      </LoadingButton>
    </Box>
  );
}
