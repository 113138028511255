// apis
import { ObjectTypesApi, getApi } from 'src/common/apis';

import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isDataLoaded: false,
  isLoading: false,
  error: false,
  hasMore: false,
  accountsNo: [],
  banks: [],
  merchants: []
};

const slice = createSlice({
  name: 'objectTypes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // set object types with api
    setObjectTypesInitial(state, action) {
      return {
        ...state,
        ...action.payload,
        isDataLoaded: true,
        isLoading: false
      };
    },

    setObjectTypes(state, action) {
      const { accountsNo, banks, merchants } = extractObjectTypes(action.payload);

      return {
        ...state,
        accountsNo: [...accountsNo, ...state.accountsNo],
        banks: [...banks, ...state.banks],
        merchants: [...merchants, ...state.merchants]
      };
    },

    noHasMore(state) {
      state.hasMore = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setObjectTypes } = slice.actions;

// ----------------------------------------------------------------------

export function getObjectTypesInitial(limit = 2000, types = ['accountNo', 'bank', 'merchant']) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await getApi({ url: `${ObjectTypesApi.base}?limit=${limit}${generateParams(types)}` });

      if (res.error || !res?.data?.objectTypes?.length) return;

      const { objectTypes, count } = res.data;

      const { accountsNo, banks, merchants } = extractObjectTypes(objectTypes);

      dispatch(
        slice.actions.setObjectTypesInitial({
          accountsNo,
          banks,
          merchants,
          hasMore: objectTypes.length < count
        })
      );
    } catch (error) {
      console.log('ERROR: ', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

const generateParams = (name, values = []) => {
  let params = '';
  values.forEach((item) => {
    params += `&${name}=${item}`;
  });
  return params;
};

const extractObjectTypes = (objectTypes = []) => {
  const accountsNo = [];
  const banks = [];
  const merchants = [];

  objectTypes.forEach((item) => {
    if (item.type === 'accountNo') {
      accountsNo.push({ label: item.name, value: item._id });
    } else if (item.type === 'bank') {
      banks.push({ label: item.name, value: item._id });
    } else if (item.type === 'merchant') {
      merchants.push({ label: item.name, value: item._id });
    }
  });
  return { accountsNo, banks, merchants };
};
