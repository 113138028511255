import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, Card, TextField, Grid, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// utils

import { ChangePasswordSchema } from 'src/common/schemas';
import { useAuth } from 'src/hooks';

// ----------------------------------------------------------------------

export default function AccountChangePassword() {
  const { changePassword } = useAuth();
  const changePasswordSchema = new ChangePasswordSchema();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  const formik = useFormik({
    initialValues: changePasswordSchema.initialValues,
    validationSchema: changePasswordSchema.schema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        await changePassword(values);

        setSubmitting(false);
      } catch (error) {
        setErrors({ afterSubmit: error.code });
        setSubmitting(false);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3} alignItems="flex-end">
                <TextField
                  {...getFieldProps('oldPassword')}
                  fullWidth
                  autoComplete="on"
                  type="password"
                  label="Old Password"
                  error={Boolean(touched.oldPassword && errors.oldPassword)}
                  helperText={touched.oldPassword && errors.oldPassword}
                />

                <TextField
                  {...getFieldProps('newPassword')}
                  fullWidth
                  autoComplete="on"
                  type="password"
                  label="New Password"
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  helperText={(touched.newPassword && errors.newPassword) || 'Password must be minimum 6+'}
                />

                <TextField
                  {...getFieldProps('confirmNewPassword')}
                  fullWidth
                  autoComplete="on"
                  type={showConfirmPassword ? 'text' : 'password'}
                  label="Confirm New Password"
                  error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
                  helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleToggleConfirmPassword} edge="end">
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
