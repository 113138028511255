export const EmptyTelegramIntegration = {
  phoneNumber: '',
  isLoggedIn: false,
  allChannels: [],
  selectedChannels: []
};

export const EmptyPlatformIntegrations = {
  telegram: EmptyTelegramIntegration
};
