const EmptyBadRequest = {
  error: 'Bad Request',
  message: 'Oops something went wrong',
  statusCode: 400
};

const ROOTS_TITLE = 'ByteScrum Expense Manager';

function title(root, sublink) {
  return `${sublink} | ${root}`;
}

const CPageTitles = {
  root: ROOTS_TITLE,
  categories: {
    root: title('Categories', ROOTS_TITLE),
    list: title('Categories List', ROOTS_TITLE),
    create: title('Create Category', ROOTS_TITLE),
    edit: title('Edit Category', ROOTS_TITLE)
  },
  expenseTypes: {
    root: title('Expnese Types', ROOTS_TITLE),
    list: title('Expnese Types List', ROOTS_TITLE),
    create: title('Create Expense Type', ROOTS_TITLE),
    edit: title('Edit Expense Type', ROOTS_TITLE)
  },
  transactions: {
    root: title('Transactions', ROOTS_TITLE),
    list: title('Transactions List', ROOTS_TITLE),
    create: title('Create Transaction', ROOTS_TITLE),
    edit: title('Edit Transaction', ROOTS_TITLE)
  },
  integrations: {
    root: title('Integration', ROOTS_TITLE),
    telegram: title('Telegram', ROOTS_TITLE)
  },
  mainDashboard: {
    root: title('Main Dashboard', ROOTS_TITLE),
    expenseDashboard: title('Expense Dashboard', ROOTS_TITLE)
  }
};

export { EmptyBadRequest, CPageTitles };

export const CPhoneRegExp = /^\+(?:[0-9]●?){6,14}[0-9]$/;
