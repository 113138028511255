const base = '/auth';

const AuthApi = {
  base,
  login: base + '/login',
  signup: base + '/signup',
  changePassword: base + '/change-password',
  generate2FA: base + '/2fa/generate',
  authenticate2FA: base + '/2fa/authenticate',
  verify2FA: base + '/2fa/verify',
  disable2FA: base + '/2fa/disable'
};

export default AuthApi;
