import toast from 'react-hot-toast';
import { axiosInstance } from 'src/utils';

const EmptyBadRequest = {
  error: 'Bad Request',
  message: 'Oops something went wrong',
  statusCode: 400
};

// common get api
export async function getApi({ url, showToast = false, message, params }) {
  const request = axiosInstance.get(url, {
    params: params
  });
  return await handleAPIReturn(request, showToast, message);
}

// common post api
export async function postApi({ url, values, showToast = false, message }) {
  const request = await axiosInstance.post(url, values);

  return await handleAPIReturn(request, showToast, message);
}

export async function formpostApi({ url, values, showToast = false, message }) {
  const request = await axiosInstance.post(url, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return await handleAPIReturn(request, showToast, message);
}

// common delete api
export async function deleteApi({ url, values, showToast = false, message }) {
  const request = await axiosInstance.delete(url, values);

  return await handleAPIReturn(request, showToast, message);
}

// common patch api
export async function patchApi({ url, values, showToast = false, message }) {
  const request = await axiosInstance.patch(url, values);

  return await handleAPIReturn(request, showToast, message);
}
// delete receipt file api
// export async function deleteApi({ url, values, showToast = false, message }) {
//   const request = await axiosInstance.delete(url, values);

//   return await handleAPIReturn(request, showToast, message);
// }
export async function formpatchApi({ url, values, showToast = false, message }) {
  const request = await axiosInstance.patch(url, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return await handleAPIReturn(request, showToast, message);
}

// Handle API data to component
const handleAPIReturn = async (request, showToast, message) => {
  try {
    const result = await request;
    const { data } = result;

    if (showToast) {
      if (data?.error || !data?.data) toast.error(message || data?.message ? data?.message : EmptyBadRequest.message);
      else toast.success(message || data?.message ? data?.message : 'Success');
    }
    return data;
  } catch (error) {
    const { data } = error.response;
    console.log('log: data', data);
    console.log('log: data', message);
    if (showToast) {
      toast.error(message || data?.message ? data?.message : EmptyBadRequest.message);
    }
    return data || EmptyBadRequest;
  }
};

// NOTE: API response structure
// const successRes = {
//   data: any,
//   statusCode: number,
//   message: string,
//   error: false
// };

// const errorRes = {
//   data: any,
//   statusCode: number,
//   message: string,
//   error: string
// };
