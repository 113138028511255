import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';

// constants
import localstorageKeysConstant from 'src/common/constants/localstorage-keys.constant';

// configs
import axiosInstance from './axiosInstance';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

const setSession = (accessToken, user) => {
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

const setLocalUser = (user) => {
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
  }
};

const getSession = () => {
  let user = localStorage.getItem(localstorageKeysConstant.USER);
  const accessToken = localStorage.getItem(localstorageKeysConstant.ACCESS_TOKEN);

  if (user) {
    user = JSON.parse(user);
  }

  return { user, accessToken };
};

const generateAuthUserData = (user) => {
  return {
    displayName: user.firstName,
    email: user.username,
    photoURL: '/static/mock-images/avatars/avatar_default.jpg',
    phoneNumber: '+40 777666555',
    country: 'India',
    address: 'Vibhuti Khand, Lucknow',
    state: 'Uttar Pradesh',
    city: 'Lucknow',
    zipCode: '226016',
    about: 'ByteScrum Technologies Pvt. Ltd.',
    role: 'admin',
    ...user
  };
};

export { isValidToken, setSession, verify, sign, getSession, generateAuthUserData, setLocalUser };
