import axios from 'axios';
import { toast } from 'react-hot-toast';
import { ENV } from 'src/common/config';
import { CLocalstorageKeys } from 'src/common/constants';
import { setSession } from './jwt';


const axiosInstance = axios.create({
  baseURL: ENV.backendAPI
});

// Where you would set stuff like your 'Authorization' header, etc ...
if (localStorage.getItem(CLocalstorageKeys.ACCESS_TOKEN)) {
  const accessToken = localStorage.getItem(CLocalstorageKeys.ACCESS_TOKEN);
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

axiosInstance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    console.log('intercepter', error);
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // console.log(error.response)
      // alert(error.response)
      toast.error(error.response.statusText);
      setSession(null);
      localStorage.clear()
      window.location.replace("/")
    }
    //   return error;
    return Promise.reject(error);
  }
);

export default axiosInstance;
