const base = '/telegram';

const TelegramApi = {
  base: base,
  login: base + '/login',
  sendCode: base + '/send-code',
  getTelegramSetting: base + '/telegram-settings',
  updateTelegramSetting: base + '/update-telegram-settings',
  logout: base + '/logout'
};

export default TelegramApi;
